import React from "react";
import styled from "styled-components";
// Components
import ServiceBox from "../Elements/ServiceBox";
// Assets
import Image1 from "../../assets/img/all/Services/5d3d7f022e4b2-2.png";
import Image2 from "../../assets/img/all/Services/7531ad93-6545-4f1d-a128-013e34377e0e-1.jpg";
import Image3 from "../../assets/img/all/Services/768f7156-38db-43f8-8879-e8c277fcba4c-1.jpg";
import Image4 from "../../assets/img/all/Services/aecd7cd2-4334-43f1-a226-c2202ed0f0fe-1.jpg";

export default function Services() {
  const Wrapper = styled.section`
    width: 100%;
  `;
  const ServiceBoxRow = styled.div`
    width: 100%;
  `;
  const LineSeparator = styled.hr`
    width: 25%;
    height: 3px;
    margin: auto;
    opacity: 1;
    border: none;
  `;
  const HeaderInfo = styled.div`
  margin-auto;
   @media (max-width: 860px) {
    text-align: center;
  }
  & h2 {
    width: fit-content;
  }
`;
  const ServiceBoxWrapper = styled.div`
    padding: 60px 0;
    @media (max-width: 860px) {
      text-align: center;
      padding: 40px 0;
    }
  `;

  const data = [
    {
      ServiceImg: Image1,
      altImg: "Image1",
      title: "إستشارات فنية في مجال هندسة المياه",
      subtitle:
        "تعتبر شركة الهندسية للمشروعات التكميلية من كبري  الشركات المتخصصة في مجال هندسة المياه",
    },
    {
      ServiceImg: Image2,
      altImg: "Image2",
      title: "تصميم محطات المياه",
      subtitle:
        "نقوم بعمل التصميمات الهندسية لمحطات تحلية المياه و محطات معالجة المياه بإستخدام أحدث البرامج المتخصصة في هندسة المياه",
    },
    {
      ServiceImg: Image3,
      altImg: "Image3",
      title: "صيانة و تركيب محطات المياه",
      subtitle:
        "تقدم الشركة الهندسية للمشروعات التكميلية خدمة الصيانة و الإحلال و التجديد و لجميع محطات المياه و خدمة بعد البيع عن طريق المتابعة الدورية",
    },
    {
      ServiceImg: Image4,
      altImg: "Image4",
      title: "تحليل المياه",
      subtitle:
        "تعتبر خدمة تحليل المياه من أهم العوامل التي تؤثر علي تصميم محطات المياه و مدى كفاءة عمل المحطة حيث لدينا أحدث أجهزة القياس و التحليل الكيميائي",
    },
  ];

  return (
    <Wrapper id="services">
      <div style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h2 className="font50 extraBold m-auto text-center">
              خدماتنا
              <LineSeparator className="cyanBg" />
            </h2>
          </HeaderInfo>
          <ServiceBoxRow className="row">
            {data.map((item) => {
              const { ServiceImg, altImg, title, subtitle } = item;
              return (
                <ServiceBoxWrapper className="col-xs-12 col-md-6" key={title}>
                  <ServiceBox
                    img={ServiceImg}
                    alt={altImg}
                    title={title}
                    subtitle={subtitle}
                  />
                </ServiceBoxWrapper>
              );
            })}
          </ServiceBoxRow>
        </div>
      </div>
    </Wrapper>
  );
}
