import React from "react";
import styled from "styled-components";
// Components
// Assets
import Image01 from "../../assets/img/all/Projects/066f7b23-70f0-44c3-9aa3-655735db5c15.jpg";
import Image02 from "../../assets/img/all/Projects/2c43b120-2b04-4ea8-b6e1-5381b22aa2df.jpg";
import Image03 from "../../assets/img/all/Projects/30d62c75-7ee1-41f9-a55f-4976df79ddb3.jpg";
import Image04 from "../../assets/img/all/Projects/396e7d79-e7c3-4b93-94b0-16c23d1f1744.jpg";
import Image05 from "../../assets/img/all/Projects/441ab78e-a743-444c-948f-9d0f68237df6.jpg";
import Image06 from "../../assets/img/all/Projects/55de340f-1f2e-4af1-b162-427e5f9a73a4.jpg";
import Image07 from "../../assets/img/all/Projects/5983857e-3601-4573-8a71-c4edf0346ff8.jpg";
import Image08 from "../../assets/img/all/Projects/6f025ddb-7105-43e6-8a9e-07a6b5958668.jpg";
import Image09 from "../../assets/img/all/Projects/72d72e10-3875-4306-8b54-4fe4565823a8.jpg";
import Image10 from "../../assets/img/all/Projects/753e02f4-de24-4fd0-b948-78af7d8d8ef2.jpg";
import Image11 from "../../assets/img/all/Projects/76415257-132c-4bac-bb40-a546d55dd3db.jpg";
import Image13 from "../../assets/img/all/Projects/a157e98d-ac6a-4a88-a249-c03dfd3ab079.jpg";
import Image14 from "../../assets/img/all/Projects/cc648dd1-d4c1-448d-8bab-2c248f27cd94.jpg";
import Image15 from "../../assets/img/all/Projects/e394bfb7-0643-436f-b498-351f628f19bd.jpg";

function Projects() {
  return (
    <Wrapper id="projects">
      <div style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h2 className="font50 extraBold m-auto text-center">
              مشروعاتنا
              <LineSeparator className="cyanBg my-2" />
            </h2>
            <h3 className="m-auto text-center mb-3">صور بعض المشروعات</h3>
          </HeaderInfo>
          <ProjectBoxRow className="row">
            {data.map((item) => {
              const { projectImg, altImg } = item;
              return (
                <div key={altImg} className="col-xs-12 col-md-6 col-xl-4">
                  <img
                    key={altImg}
                    className=""
                    src={projectImg}
                    alt={altImg}
                    width={500}
                    height={500}
                  />
                </div>
              );
            })}
          </ProjectBoxRow>
        </div>
      </div>
    </Wrapper>
  );
}

export default Projects;

const Wrapper = styled.section`
  width: 100%;
`;
const ProjectBoxRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  & img {
    width: 100%;
    height: auto;
    margin: 5px;
  }
`;
const LineSeparator = styled.hr`
  width: 25%;
  height: 3px;
  margin: auto;
  opacity: 1;
  border: none;
`;
const HeaderInfo = styled.div`
margin-auto;
@media (max-width: 860px) {
text-align: center;
}
& h2 {
width: fit-content;
}
`;
const data = [
  {
    projectImg: Image05,
    altImg: "Image05",
  },
  {
    projectImg: Image06,
    altImg: "Image06",
  },
  {
    projectImg: Image14,
    altImg: "Image14",
  },
  {
    projectImg: Image01,
    altImg: "Image1",
  },
  {
    projectImg: Image02,
    altImg: "Image2",
  },
  {
    projectImg: Image04,
    altImg: "Image4",
  },
  {
    projectImg: Image07,
    altImg: "Image07",
  },
  {
    projectImg: Image08,
    altImg: "Image08",
  },
  {
    projectImg: Image09,
    altImg: "Image09",
  },
  {
    projectImg: Image10,
    altImg: "Image10",
  },
  {
    projectImg: Image13,
    altImg: "Image13",
  },

  {
    projectImg: Image11,
    altImg: "Image11",
  },
  {
    projectImg: Image03,
    altImg: "Image3",
  },
  {
    projectImg: Image15,
    altImg: "Image15",
  },
];
