import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import CloseIcon from "../../assets/svg/CloseIcon";
import LogoIcon from "../../assets/img/all/logo/ESPLogo.png";

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  const [htmlDir, setHtmlDir] = useState("rtl");

  useEffect(() => {
    setHtmlDir(document.documentElement.dir);
  }, []);

  const Wrapper = styled.nav`
    width: 400px;
    height: 100vh;
    position: fixed;
    top: 0;
    padding: 0 30px;
    background-color: #fff !important;

    ${htmlDir === "rtl"
      ? (props) => (props.sidebarOpen ? "left: 0px;" : "left: -500px;")
      : (props) => (props.sidebarOpen ? "right: 0px;" : "right: -500px;")}
    z-index: 9999;
    @media (max-width: 400px) {
      width: 100%;
    }
  `;
  const SidebarHeader = styled.div`
    padding: 20px 0;
  `;
  const CloseBtn = styled.button`
    border: 0px;
    outline: #000;
    background-color: transparent;
    padding: 10px;
  `;
  const UlStyle = styled.ul`
    padding: 40px;
    li {
      margin: 20px 0;
    }
  `;
  const LogoImage = styled.img`
    width: 150px;
    height: auto;
  `;

  return (
    <Wrapper className="animate darkBg" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter">
          <LogoImage src={LogoIcon} alt="esp-logo" width={200} height={200} />
        </div>
        <CloseBtn
          onClick={() => toggleSidebar(!sidebarOpen)}
          className="animate pointer"
        >
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>

      <UlStyle className="flexNullCenter flexColumn">
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="darkColor"
            style={{ padding: "10px 15px" }}
            to="home"
            spy={true}
            smooth={true}
            offset={-40}
          >
            <h1>الرئيسية</h1>
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="darkColor"
            style={{ padding: "10px 15px" }}
            to="products"
            spy={true}
            smooth={true}
            offset={-80}
          >
            منتجاتنا
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="darkColor"
            style={{ padding: "10px 15px" }}
            to="services"
            spy={true}
            smooth={true}
            offset={-60}
          >
            خدماتنا
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="darkColor"
            style={{ padding: "10px 15px" }}
            to="projects"
            spy={true}
            smooth={true}
            offset={-60}
          >
            مشروعاتنا
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="darkColor"
            style={{ padding: "10px 15px" }}
            to="clients"
            spy={true}
            smooth={true}
            offset={-30}
          >
            عملائنا
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="darkColor"
            style={{ padding: "10px 15px" }}
            to="contact"
            spy={true}
            smooth={true}
            offset={-20}
          >
            تواصل معنا
          </Link>
        </li>
      </UlStyle>
    </Wrapper>
  );
}
