import React from "react";
import CountUp from "react-countup";
import img1 from "../../assets/img/all/Icons/bridge-1.png";
import img2 from "../../assets/img/all/Icons/people-1.png";
import img3 from "../../assets/img/all/Icons/purified-water-2.png";
import img4 from "../../assets/img/all/Icons/waste-1.png";
import img5 from "../../assets/img/all/Icons/water-filter-1.png";
import styled from "styled-components";

function ServicesCount() {
  const data = [
    { title: "اجمالي العملاء", count: 3800, img: img2, altImg: "people" },
    {
      title: "محطات تنقية و تحلية المياه",
      count: 700,
      img: img3,
      altImg: "purified",
    },
    { title: "محطات معالجة صرف صناعي", count: 100, img: img4, altImg: "waste" },
    { title: "محطات رفع", count: 100, img: img1, altImg: "bridge" },
    { title: "فلاتر منزلية", count: 2500, img: img5, altImg: "filter" },
  ];

  return (
    <div className="container">
      <Wrapper>
        {data.map((service) => {
          const { title, count, img, altImg } = service;
          return (
            <Service key={title}>
              <img src={img} alt={altImg} width={64} height={64} />
              <CountUp end={count} duration={4} enableScrollSpy={true} />
              <Separator />
              <p>{title}</p>
            </Service>
          );
        })}
      </Wrapper>
    </div>
  );
}

export default ServicesCount;

const Wrapper = styled.div`
  width: 100%;
  height: 223px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 991px) {
    flex-direction: column;
    margin-top: 100px;
    height: 100%;
  }
`;
const Service = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #4d4d53;
  margin-bottom: 35px;

  & img {
    width: 64px;
    height: 64px;
  }

  & span {
    font-size: 60px;
  }

  & p {
    font-size: 20px;
  }

  @media only screen and (min-width: 991px) and (max-width: 1400px) {
    & img {
      width: 50px;
      height: 50px;
    }

    & span {
      font-size: 50px;
    }

    & p {
      font-size: 15px;
    }
  }
`;
const Separator = styled.hr`
  width: 10%;
  min-height: 2px;
  background-color: #49cb86;
  opacity: 1;
  border: none;
  margin: 0 auto;
  margin-bottom: 5px;
`;
