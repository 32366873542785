import React from "react";
import img1 from "../../assets/img/all/Carousel/005.png";
import img2 from "../../assets/img/all/Carousel/006.png";
import img3 from "../../assets/img/all/Carousel/1-11.png";
import img4 from "../../assets/img/all/Carousel/4-b-1.png";
import Carousel from "react-bootstrap/Carousel";
import "./FirstSectionCarousel.css";
import FullButton from "../Buttons/FullButton";
import { Link } from "react-scroll";

const data = [
  {
    header: "عن الشركة",
    title: "الهندسية للمشروعات التكميلية",
    description:
      "تأسست الشركة الهندسية للمشروعات التكميلية عام 2006. و تضم مجموعة من المهندسين الخبراء في مجال هندسة المياه لتنفيذ مشروعات المياه في مصر و الوطن العربي",
    btnTitle: "اتصل بنا الان",
    img: img1,
    imgAlt: "about",
  },
  {
    header: "رؤيتنا",
    title: "الهندسية للمشروعات التكميلية",
    description:
      "تطوير مجال هندسة المياه و أستخدام أحدث تكنولوجيا مستخدمة عالميا في مشروعاتنا و العمل على تقديم خدمة مميزة للعملاء بأعلى جودة و أفضل سعر",
    btnTitle: "اتصل بنا الان",
    img: img2,
    imgAlt: "vision",
  },
  {
    header: "مهمتنا",
    title: "الهندسية للمشروعات التكميلية",
    description:
      "الهندسية للمشروعات التكميلية تسعى دائماً الشركة الهندسية للمشروعات التكميلية بالتحسين المستمر لجودة الخدمات التي نقدمها لعملائنا من خلال تقديم منتجات فائقة الجودة لتلبية احتياجات عملائنا ولهذا السبب نختار بعناية شركائنا وموردينا وموظفينا",
    btnTitle: "اتصل بنا الان",
    img: img3,
    imgAlt: "mission",
  },
  {
    header: "فلاتر المياه",
    title: "الهندسية للمشروعات التكميلية",
    description:
      "فلاتر المياه ذات الوسائط المتعددة حتى الآن هي النوع الأكثر شيوعا من فلاتر المياه، حيث أنها تحتوي على طبقات متعددة. تتدرج الطبقات تدريجيا في الخشونة وعمق الطبقة.",
    btnTitle: "اتصل بنا الان",
    img: img4,
    imgAlt: "filters",
  },
];

function FirstSectionCarousel() {
  return (
    <div>
      <div id="home" className="container-fluid carousel-container">
        <Carousel>
          {data.map((item) => {
            const { header, title, description, btnTitle, img, imgAlt } = item;
            return (
              <Carousel.Item key={header}>
                <div className="row d-flex flex-column-reverse flex-lg-row">
                  <div className="col-xs-12 col-lg-6 text-container d-flex justify-content-center align-items-center">
                    <div className="d-flex-column justify-content-center align-items-center text-center w-100">
                      <h2 className="darkColor">{header}</h2>
                      <h3 className="redColor">{title}</h3>
                      <p className="greyColor">{description}</p>
                      <div className="w-100">
                        <Link
                          activeClass="active"
                          style={{
                            display: "block",
                            padding: "10px 15px",
                            margin: "auto",
                            width: "50%",
                          }}
                          to="contact"
                          spy={true}
                          smooth={true}
                        >
                          <FullButton title={btnTitle} />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-lg-6 img-side-container d-flex justify-content-center align-items-center">
                    <div className="img-container">
                      <img
                        className="d-block w-100 h-100"
                        src={img}
                        alt={imgAlt}
                        width={500}
                        height={500}
                      />
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
}

export default FirstSectionCarousel;
