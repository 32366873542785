import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Assets
import ClientLogo01 from "../../assets/img/all/Clients/01-1-1-2.png";
import ClientLogo02 from "../../assets/img/all/Clients/NIOSH-2.png";
import ClientLogo03 from "../../assets/img/all/Clients/daddys-2.png";
import ClientLogo04 from "../../assets/img/all/Clients/eg-2.png";
import ClientLogo06 from "../../assets/img/all/Clients/elsewedy-2.png";
import ClientLogo07 from "../../assets/img/all/Clients/epico-2.png";
import ClientLogo08 from "../../assets/img/all/Clients/granito-2.png";
import ClientLogo09 from "../../assets/img/all/Clients/iiii-1-2.png";
import ClientLogo10 from "../../assets/img/all/Clients/isis-1-1-2.png";
import ClientLogo11 from "../../assets/img/all/Clients/lazurde-2.png";
import ClientLogo12 from "../../assets/img/all/Clients/mandarine-2.png";
import ClientLogo13 from "../../assets/img/all/Clients/marasi-2.png";
import ClientLogo14 from "../../assets/img/all/Clients/petojet-2.png";
import ClientLogo15 from "../../assets/img/all/Clients/phosphate-1-2.png";
import ClientLogo16 from "../../assets/img/all/Clients/sekem-2.png";
import ClientLogo17 from "../../assets/img/all/Clients/دواجن-الوطنية-1.png";

export default function ClientSlider() {
  const clients = [
    { logo: ClientLogo01, alt: "ClientLogo01" },
    { logo: ClientLogo02, alt: "ClientLogo02" },
    { logo: ClientLogo03, alt: "ClientLogo03" },
    { logo: ClientLogo04, alt: "ClientLogo04" },
    { logo: ClientLogo06, alt: "ClientLogo06" },
    { logo: ClientLogo07, alt: "ClientLogo07" },
    { logo: ClientLogo08, alt: "ClientLogo08" },
    { logo: ClientLogo09, alt: "ClientLogo09" },
    { logo: ClientLogo10, alt: "ClientLogo10" },
    { logo: ClientLogo11, alt: "ClientLogo11" },
    { logo: ClientLogo12, alt: "ClientLogo12" },
    { logo: ClientLogo13, alt: "ClientLogo13" },
    { logo: ClientLogo14, alt: "ClientLogo14" },
    { logo: ClientLogo15, alt: "ClientLogo15" },
    { logo: ClientLogo16, alt: "ClientLogo16" },
    { logo: ClientLogo17, alt: "ClientLogo17" },
  ];

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <Slider className="client-silder" {...settings}>
        {clients.map((clientLogo) => {
          const { logo, alt } = clientLogo;
          return (
            <LogoWrapper key={alt} className="flexCenter">
              <ImgStyle src={logo} alt={alt} width={150} height={150} />
            </LogoWrapper>
          );
        })}
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  display: flex !important;
  justify-content: center;
  width: 100%;
  height: fit-content;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
const ImgStyle = styled.img`
  width: 150px;
  height: 150px;
  padding: 10%;
`;
