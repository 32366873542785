import React from "react";
import styled from "styled-components";

export default function ProductBox({ img, title, alt }) {
  return (
    <Wrapper>
      <ImgContainer>
        <img className="radius8" src={img} alt={alt} width={500} height={400} />
      </ImgContainer>
      <Separator />
      <h3 className="font20 extraBold mt-2">{title}</h3>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 520px;
  margin: 30px auto;
  border-radius: 1rem;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  img {
    width: 100%;
    margin-bottom: 20px 0;
    object-fit: contain;
  }
  h3 {
    padding-bottom: 10px;
  }
  transition: all ease-in-out 0.4s;
  &:hover {
    transform: scale(1.05);
  }

  @media (min-width: 768px) {
    min-height: 380px;
  }
  @media (min-width: 900px) {
    min-height: 400px;
  }
  @media (min-width: 991px) {
    min-height: 550px;
  }
  @media (min-width: 991px) {
    min-height: 550px;
  }
  @media (min-width: 1200px) {
    min-height: 490px;
  }
  @media (max-width: 860px) {
    width: 90%;
  }
`;
const ImgContainer = styled.div`
  display: block;
  background-color: transparent;
  border: 0px;
  outline: none;
  padding: 0px;
  margin: 0px;
  max-height: 416px;
  width: 100%;
  object-fit: contain;

  & img {
  }
`;
const Separator = styled.hr`
  width: 10%;
  min-height: 2px;
  background-color: #49cb86;
  opacity: 1;
  border: none;
  margin: 10px auto;
`;
