import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import { BsFacebook, BsLinkedin } from "react-icons/bs";
import { IoLogoYoutube } from "react-icons/io";
// Assets

export default function Contact() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper
            className="flexSpaceCenter"
            style={{ padding: "30px 0" }}
          >
            <SocialWrapper>
              <a
                className="mx-3"
                href="https://www.facebook.com/esp.aqua.smart.egypt"
              >
                <BsFacebook size={25} color="#41d7c2" />
              </a>
              <a
                className="mx-3"
                href="https://www.linkedin.com/company/esp-egypt/"
              >
                <BsLinkedin size={25} color="#41d7c2" />
              </a>
              <a className="mx-3" href="https://www.youtube.com/c/ESPEGYPT">
                <IoLogoYoutube size={28} color="#41d7c2" />
              </a>
            </SocialWrapper>

            <StyleP className="whiteColor font13">
              © {getCurrentYear()} -{" "}
              <span className="cyanColor font13">ESP</span> All Right Reserved
            </StyleP>

            <Link
              className="whiteColor animate pointer font13"
              to="home"
              smooth={true}
              offset={-80}
            >
              Back to top
            </Link>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;
const SocialWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
