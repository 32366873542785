import React from "react";
import styled from "styled-components";

export default function FullButton({ title, action, border }) {
  return (
    <Wrapper
      className="animate pointer radius8"
      onClick={action ? () => action() : null}
      border={border}
    >
      {title}
    </Wrapper>
  );
}

const Wrapper = styled.button`
  border: 1px solid ${(props) => (props.border ? "white" : "#ff2a00")};
  background-color: ${(props) => (props.border ? "transparent" : "#ff2a00")};
  width: 100%;
  padding: 15px;
  outline: none;
  color: ${(props) => (props.border ? "white" : "white")};
  transition: all ease-in-out 0.4s;
  :hover {
    background-color: ${(props) => (props.border ? "transparent" : "#ff2a00")};
    border: 1px solid #ff2a00;
    color: ${(props) => (props.border ? "white" : "white")};
    transform: scale(1.05);
  }
`;
