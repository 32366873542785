import React from "react";
import styled from "styled-components";
// Components
import ProductBox from "../Elements/ProductBox";
// Assets
import productImg1 from "../../assets/img/all/Products/Filter-ALK-Pure-Pro-1-1-1-300x300.png";
import productImg3 from "../../assets/img/all/Products/WhatsApp-Image-2022-09-28-at-14.20.19-1-300x300.jpg";
import productImg4 from "../../assets/img/all/Products/9bbe13c8-fdfb-4374-978b-381c97632f6d.jpg";
import productImg5 from "../../assets/img/all/Products/0cb94f36-a477-4ba8-862c-1e6f3ec07333.jpg";
import productImg6 from "../../assets/img/all/Products/5a3ef621-cf57-4146-bdac-173ce85dec7c.jpg";
import productImg7 from "../../assets/img/all/Products/9d28d5ab-cddc-4a9c-997f-781b0d98a176.jpg";
import productImg8 from "../../assets/img/all/Products/9d499993-b545-490a-8b75-e3520abeca9e.jpg";

export default function Products() {
  const Wrapper = styled.section`
    width: 100%;
  `;
  const LineSeparator = styled.hr`
    width: 25%;
    height: 3px;
    margin: auto;
    opacity: 1;
    border: none;
    margin: 3px auto;
  `;
  const HeaderInfo = styled.div`
    margin-auto;
    @media (max-width: 860px) {
      text-align: center;
    }
    & h2 {
      width: fit-content;
    }
  `;
  const data = [
    {
      productImg: productImg3,
      imgAlt: "محطات المياه",
      title: "محطات المياه",
    },
    {
      productImg: productImg4,
      imgAlt: "مضخات مياه",
      title: "مضخات مياه",
    },
    {
      productImg: productImg8,
      imgAlt: "قطع غيار محطات تحلية",
      title: "قطع غيار محطات تحلية",
    },
    {
      productImg: productImg7,
      imgAlt: "فيزل فيبر جلاس",
      title: "فيزل فيبر جلاس",
    },
    {
      productImg: productImg5,
      imgAlt: "هاوسينج فلاتر",
      title: "هاوسينج فلاتر",
    },
    {
      productImg: productImg6,
      imgAlt: "ميديا و كيماويات",
      title: "ميديا و كيماويات",
    },
    {
      productImg: productImg1,
      imgAlt: "فلاتر منزلية",
      title: "فلاتر منزلية",
    },
  ];

  return (
    <Wrapper id="products">
      <div>
        <div className="container">
          <HeaderInfo>
            <h2 className="font40 extraBold m-auto darkColor">
              منتجاتنا
              <LineSeparator className="cyanBg" />
            </h2>
          </HeaderInfo>
          <div className="row textCenter">
            {data.map((product) => {
              const { productImg, imgAlt, title } = product;
              return (
                <div key={title} className="col-xs-12 col-md-6 col-xl-4">
                  <ProductBox img={productImg} title={title} alt={imgAlt} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
