import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Services from "../components/Sections/Services";
import Products from "../components/Sections/Products";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer";
import FirstSectionCarousel from "../components/Sections/FirstSectionCarousel";
import Clients from "../components/Sections/Clients";
import "./Landing.css";
import ServicesCount from "../components/Sections/ServicesCount";
import whatsappIcon from "../assets/img/all/Icons/—Pngtree—whatsapp phone icon_8704826.png";
import phoneCall from "../assets/img/all/Icons/icons8-call-96.png";
import Projects from "../components/Sections/Projects";

export default function Landing() {
  return (
    <div className="main">
      <TopNavbar />
      <div className="first-gap"></div>
      <FirstSectionCarousel />
      <div style={{ height: "19vh" }}></div>
      <Products />
      <div style={{ height: "15vh" }}></div>
      <Services />
      <div style={{ height: "15vh" }}></div>
      <Projects />
      <div style={{ height: "15vh" }}></div>
      <Clients />
      <div style={{ height: "15vh" }}></div>
      <ServicesCount />
      <div style={{ height: "15vh" }}></div>
      <Contact />
      <div style={{ height: "15vh" }}></div>
      <div className="fixed-whatsapp">
        <a href="https://wa.me/201012570030">
          <img src={whatsappIcon} alt="whatsapp" width={75} height={75} />
        </a>
      </div>
      <div className="fixed-phone-call">
        <a href="tel:201012570030">
          <img src={phoneCall} alt="phone" width={75} height={75} />
        </a>
      </div>
      <Footer />
    </div>
  );
}
