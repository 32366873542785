import React from "react";
import styled from "styled-components";
// Assets

export default function ServiceBox({ img, alt, title, subtitle }) {
  return (
    <Wrapper className="flex flexColumn">
      <ImgContainer>
        <Img src={img} alt={alt} width={500} height={500} />
      </ImgContainer>
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <Separator />
      <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 50%;
  max-height: 425px;
  margin: auto;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  transition: all ease-in-out 0.4s;
  &:hover {
    transform: scale(1.05);
  }
  @media (max-width: 860px) {
    padding: 20px 0;
    width: 80%;
    margin: auto;
  }
  @media (min-width: 861px) {
    width: 80%;
  }
  @media (min-width: 1200px) {
    width: 60%;
  }
`;
const ImgContainer = styled.div`
  background-color: #f5f5f5;
  width: 100%;
  height: 260px;
  overflow: hidden;
`;
const Img = styled.img`
  width: 100%;
  height: 260px;
  border-radius: 10px 10px 0 0;
`;
const TitleStyle = styled.h2`
  text-align: center;
  background-color: #f5f5f5;
  width: 100%;
  margin: 0 auto;
  padding: 20px 10px;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;
const SubtitleStyle = styled.p`
  text-align: center;
  background-color: #f5f5f5;
  padding: 0 10px;
  width: 100%;
  height: 100px;
  margin: 0 auto;
  border-radius: 0 0 10px 10px;
`;
const Separator = styled.hr`
  width: 35%;
  min-height: 2px;
  background-color: #49cb86;
  opacity: 1;
  border: none;
  margin: 0 auto;
  margin-bottom: 5px;
`;
