import React from "react";
import styled from "styled-components";
import { TiLocation } from "react-icons/ti";
import { MdEmail } from "react-icons/md";
import { IoMdCall } from "react-icons/io";

// Assets

export default function Contact() {
  const data = [
    {
      contactIcon: <TiLocation size={30} color="#ff2a00" />,
      contactInfo:
        "عمارة 20 زهراء مدينة نصر مرحلة أولى شقة رقم 4 - القاهرة-مصر",
    },
    {
      contactIcon: <MdEmail size={30} color="#ff2a00" />,
      contactInfo: "info@espegy.com",
    },
    {
      contactIcon: <IoMdCall size={30} color="#ff2a00" />,
      contactInfo: "المبيعات: 01012570030",
    },
    {
      contactIcon: <IoMdCall size={30} color="#ff2a00" />,
      contactInfo: "0224118992",
    },
  ];

  return (
    <Wrapper id="contact">
      <div className="">
        <div className="container">
          <HeaderInfo>
            <h2 className="font40 extraBold m-auto">
              تواصل معنا
              <LineSeparator className="cyanBg" />
            </h2>
          </HeaderInfo>
          <div className="row py-5" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex mb-5">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d431.70258236964713!2d31.384657!3d30.047739!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14583d6b8f5b00e7%3A0x63d7c84bac85b347!2zRS5TLlAgRWd5cHQg2KfZhNmH2YbYr9iz2YrYqSDZhNmE2YXYtNix2YjYudin2Kog2KfZhNiq2YPZhdmK2YTZitip!5e0!3m2!1sen!2seg!4v1686907640874!5m2!1sen!2seg"
                style={{
                  border: 0,
                  width: "100%",
                  minHeight: "300px",
                }}
                title="location"
                allowFullScreen=""
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <ContactInfoContainer>
                {data.map((item) => {
                  const { contactIcon, contactInfo } = item;
                  return (
                    <ContactInfoItem key={contactInfo}>
                      <span className="ms-2">{contactIcon}</span>
                      <span>{contactInfo}</span>
                    </ContactInfoItem>
                  );
                })}
              </ContactInfoContainer>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
// const HeaderInfo = styled.div`
//   padding: 70px 0 30px 0;
//   @media (max-width: 860px) {
//     text-align: center;
//   }
// `;
const LineSeparator = styled.hr`
  width: 25%;
  height: 3px;
  margin: auto;
  opacity: 1;
  border: none;
  margin: 6px auto;
`;
const HeaderInfo = styled.div`
margin-auto;
@media (max-width: 860px) {
  text-align: center;
}
& h2 {
  width: fit-content;
}
`;
const ContactInfoContainer = styled.div``;
const ContactInfoItem = styled.div`
  margin-bottom: 25px;
`;
