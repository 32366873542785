import React from "react";
import ClientSlider from "../Elements/ClientSlider";
import styled from "styled-components";

function Clients() {
  const Wrapper = styled.section`
    width: 100%;
    padding: 50px 0;
  `;
  const HeaderInfo = styled.div`
    margin-auto;
    text-align: center;
    & h2 {
        width: fit-content;
      }
  `;
  const LineSeparator = styled.hr`
    width: 25%;
    height: 3px;
    margin: auto;
    opacity: 1;
    border: none;
  `;
  return (
    <Wrapper id="clients">
      <HeaderInfo>
        <h2 className="font40 extraBold m-auto">
          عملائنا
          <LineSeparator className="cyanBg" />
        </h2>
      </HeaderInfo>
      <div style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div>
    </Wrapper>
  );
}

export default Clients;
