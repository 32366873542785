import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
import LogoIcon from "../../assets/img/all/logo/ESPLogo.png";
import BurgerIcon from "../../assets/svg/BurgerIcon";

export default function TopNavbar() {
  const [htmlDir, setHtmlDir] = useState("rtl");
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    setHtmlDir(document.documentElement.dir);
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  const Wrapper = styled.nav`
    width: 100%;
    position: sticky;
    top: 0;
    min-height: 80px;
    background-color: #fff !important;
    ${htmlDir === "rtl" ? "right: 0;" : "left: 0;"}
    z-index: 999;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  `;
  const LogoImage = styled.img`
    width: 150px;
    height: auto;
  `;
  const NavInner = styled.div`
    position: relative;
    height: 100%;
  `;
  const BurderWrapper = styled.button`
    outline: none;
    border: 0px;
    background-color: transparent;
    height: 100%;
    padding: 0 15px;
    display: none;
    @media (max-width: 760px) {
      display: block;
    }
  `;
  const UlWrapper = styled.ul`
    display: flex;
    @media (max-width: 760px) {
      display: none;
    }
  `;

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper className="flexCenter animate whiteBg">
        <NavInner className="container flexSpaceCenter">
          <Link className="pointer flexNullCenter" to="home" smooth={true}>
            <LogoImage src={LogoIcon} alt="esp-logo" width={200} height={200} />
          </Link>
          <BurderWrapper
            className="pointer"
            onClick={() => toggleSidebar(!sidebarOpen)}
          >
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper className="flexNullCenter">
            <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                style={{ padding: "10px 15px" }}
                to="home"
                spy={true}
                smooth={true}
                offset={-230}
              >
                الرئيسية
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                style={{ padding: "10px 15px" }}
                to="products"
                spy={true}
                smooth={true}
                offset={-80}
              >
                منتجاتنا
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                style={{ padding: "10px 15px" }}
                to="services"
                spy={true}
                smooth={true}
                offset={-80}
              >
                خدماتنا
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                style={{ padding: "10px 15px" }}
                to="projects"
                spy={true}
                smooth={true}
                offset={-80}
              >
                مشروعاتنا
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                style={{ padding: "10px 15px" }}
                to="clients"
                spy={true}
                smooth={true}
                offset={-200}
              >
                عملائنا
              </Link>
            </li>

            <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                style={{ padding: "10px 15px" }}
                to="contact"
                spy={true}
                smooth={true}
                offset={-340}
              >
                تواصل معنا
              </Link>
            </li>
          </UlWrapper>
        </NavInner>
      </Wrapper>
    </>
  );
}
